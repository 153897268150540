.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.garage {
  margin-top: -147px;
    margin-left: -308px;
    margin-right: -14px;
    width: 150px;
}

.garage-2 {
  margin-left: -100px;
}

.triciclo {
  position: absolute;
    z-index: 1;
    top: 310px;
    left: -350px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 125px;
    transition: 1s;
    opacity: 0;
}

.rueda {
  position: absolute;
  z-index: -1;
  margin-top: -43px;
  margin-left: -63px;
}

.rueda-grande {
  position: absolute;
  z-index: -1;
  margin-top: -67px;
    margin-left: 13px;
}

.flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1)!important;
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}

.triciclo.triciclo-animation {
    opacity: 1;
    left: 0px;
}
.triciclo.triciclo-animation-close {
  opacity: 0;
  left: -1100px;
}

.timeline {
  background: #525660;
  padding: 220px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  height: 540px!important;
    padding-top: 265px;
    top: 30px;
    

}

.story:first {
  margin-left: -93px!important;
}

.timeline-parent {
  position: relative;
  display: none;
  transition: opacity 1s ease-out;
    opacity: 0;

}

.timeline-img-off {
  display: none!important;
  opacity: 0;
}

.timeline-on {
  opacity: 1;
    display: block;
}

.gap-item {
  display: inline-block;
  position: relative;
}

.gap-item--image-container {
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
}

.gap-item--photo {
  /* width: 100px; */
}

.step {
  height: 13px;
  width: 13px;
  background-color: #EF2025;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
}

.story {
  position: relative;

}

.timeline-section {
  opacity: 1;
  transition: opacity 2s;
}

.timeline-step , .gap-item{
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -ms-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.opacity-o {
  opacity: 0!important;
}

.opacity-1 {
  opacity: 1!important;
}

.timeline-step--visible {
  opacity: 1;
}

.timeline-step--visible + .gap-item {
  opacity: 1;
}

.story-image {
  display: none;
}

.story-image--bottom-right {
  position: absolute;
  left: -40px;
  top: 4px;
  width: 170px;
}

.story-image--bottom-right-garita {
  position: absolute;
  left: -55px;
  top: 4px;
  width: 170px;
}

.story-image--bottom-right-pintype-down {
  position: absolute;
  left: -37px;
  top: 2px;
  width: 170px;
}

.story-image--bottom-right.dash-large {
  left: -45px;
  top: -4px;
}

.story-image--top-right {
  position: absolute;
  left: -41px;
  width: 186px;
  bottom: -21px;
}

.story-image--top-right-b {
  position: absolute;
  left: -58px;
  width: 186px;
  bottom: -21px;
}

.story-image--top-right-pintype-up {
  position: absolute;
  left: -41px;
  width: 186px;
  bottom: -21px;
}

.story-image--top-right-pintype-up-b {
  position: absolute;
  left: -55px;
  width: 186px;
  bottom: -27px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.t-center {
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .t-center {
    text-align: center!important;
  }
}

.items-carrusel img {
    max-width: 100%!important;
}

#awards img {
  max-width: 100%!important;
}

.story-image--end-image--bottom-right {
  width: 400px;
  top: -137px;
  left: 100px;
  position: absolute;
}

.story-image--end-image--bottom-right-50 {
  width: 400px;
  top: -137px;
  left: 100px;
  position: absolute;
}

.story-image--grage-image {
    left: -162px;
    top: -146px;
    width: 150px;
    position: absolute;
}

.close-btn {
  position: absolute;
  z-index: 1!important;
  top: 100px!important;
  /* left: 1340px; */
  right: 100px!important;
  /* bottom: 350px; */
  /* float: right; */
  cursor: pointer;
  color: #FF8800;
}

.test1 {
  padding-right: 1400px!important;
}

@media only screen and (max-width: 2300px) {
  .test1 {
    padding-right: 2100px!important;
  }
}

@media only screen and (max-width: 2000px) {
  .test1 {
    padding-right: 1900px!important;
  }
}

@media only screen and (max-width: 1920px) {
  .test1 {
    padding-right: 1820px!important;
  }
}

@media only screen and (max-width: 1600px) {
  .test1 {
    padding-right: 1500px!important;
  }
}

@media only screen and (max-width: 1536px) {
  .test1 {
    padding-right: 1436px!important;
  }
}

@media only screen and (max-width: 1440px) {
  .test1 {
    padding-right: 1340px!important;
  }
}

@media only screen and (max-width: 1366px) {
  .test1 {
    padding-right: 1266px!important;
  }
}

@media only screen and (max-width: 1280px) {
  .test1 {
    padding-right: 1180px!important;
  }

  .story-image--end-image--bottom-right {
    width: 300px!important;
    top: -110px;

  }
}

@media only screen and (max-width: 991px) {
  .test1 {
    padding-right: 1085px!important;
  }

  .row>* {
    width: 100%!important;
  }

  .timeline {
    overflow-x: scroll!important;
    overflow-y: hidden!important;
    -webkit-overflow-scrolling: touch!important;
  }
}

@media only screen and (max-width: 780px) {
  .test1 {
    padding-right: 900px!important;
  }
}

@media only screen and (max-width: 480px) {

  .timeline {
    height: 0px!important;
    padding-top: 198px!important;
  }
  .story-image--grage-image {
    left: -125px!important;
    top: -100px!important;
    width: 106px!important;

  }

  .triciclo {
    left: -190px;
  }


  .triciclo-svg {
    width: 100px!important;
    margin-top: -43px;
  }

  .rueda {
    margin-top: 34px;
    margin-left: -100px;
    width: 30px;
  }
  
  .rueda-grande {
    margin-top: 13px;
    margin-left: -48px;
    width: 48px;
  }

  .test1 {
  padding-right: 450px!important;
  }

  .story-image--end-image--bottom-right {
    width: 400px!important;
    top: -155px!important;
  }

  .story-image--end-image--bottom-right-50 {
    z-index: 2;
    width: 300px;
    top: -117px;
    left: 153px;
  }

  #story {
    opacity: 1!important;
  }

  .img-responsive {
    width: 70%!important;
    margin-top: 16px!important;
    padding: 0px!important;

  }

  .garage-2 {
    margin-left: -42px!important;
  }

  .timeline-step , .gap-item{
    opacity: 1;
    
  }

}

.row>* {

  width: 20%;
  
}

.row {

  margin-right: 0; 
  
}


.timeline-btn {
  max-width: 100%;
}



.story-text--up-pin-type {
  position: absolute;
  top: -85px;
  left: 22px;
  text-align: left;
  color: white;
}

.story-text--up-dots-type {
  position: absolute;
  top: -57px;
  left: -1px;
  text-align: left;
  color: white;
}

.story-text--down-dots-type {
  position: absolute;
  top: 19px;
  left: -5px;
  text-align: left;
  color: white;

}

.story-text--down-pin-type {
  position: absolute;
  top: 19px;
  left: 25px;
  text-align: left;
  color: white;

}

.story-text--small-y {
  font-size: 12px!important;
  position: absolute;
  top: 50px;
  left: 15px;
  color: white;
  white-space: normal!important;
  width: 240px;
  text-align: start;
}

.story-text--small-up {
  font-size: 12px!important;
  position: absolute;
  top: -51px;
    left: -5px;
  color: white;
  white-space: normal!important;
  width: 240px;
  text-align: start;
}
.story-text--small-up-y {
  font-size: 12px!important;
  position: absolute;
  top: -52px;
  left: 25px;
  color: white;
  white-space: normal!important;
  width: 240px;
  text-align: start;
}

.story-text--small-down {
  font-size: 12px!important;
  position: absolute;
  top: 25px;
    left: -5px;
  color: white;
  width: 100px;
  white-space: normal!important;
  width: 240px;
  text-align: start;
}

.story-text--small-down-y {
  font-size: 12px!important;
  position: absolute;
  top: 50px;
  left: 27px;
  color: white;
  width: 100px;
  white-space: normal!important;
  width: 240px;
  text-align: start;
}

.yearStyle--up {
  position: absolute;
  top: -80px;
  left: -7px;
  color: white;
  text-align: start;

}

.yearStyle--down {
  position: absolute;
  top: -80px;
  left: -7px;
  color: white;
  text-align: start;

}

.yearStyle--up-custom-pin {
  position: absolute;
  top: -80px;
  left: 22px;
  color: white;
  text-align: start;

}

.story-text--small-up-custom-pin {
  font-size: 12px!important;
  position: absolute;
  top: -52px;
  left: 23px;
  color: white;
  white-space: normal!important;
  width: 240px;
  text-align: start;
}

.custom-text--1 {
  top: -79px!important;
  left: 1px!important;
}

.custom-text--2 {
  left: -4px!important;
}

.custom-text--3 {
  left: 23px!important;
}

.custom-text--4 {
  left: 35px!important;
}

.custom-text--5{
  top:  -80px;
  left: -5px!important;
}

@media only screen and (max-width: 480px) {

  .close-btn {
    top: 90px!important;
    left: 320px;
    
  }
  .story-text--down-pin-type {
    top: 25px!important;
  }

  .story-text--down-dots-type {
    top: 25px!important;
  }

  .story-text--up-pin-type {
    top: -80px!important;
  }

  .story-text--up-dots-type.yearStyle--up {
    top: -75px!important;
  }
}


.wrap-timeline-img {
  max-width: 1000px!important;
  margin: 0 auto!important;
}

.display-none {
  display: none!important;
}

.display {
  display: block!important;
}

.display--opacity-1 {
  display: block!important;
  opacity: 1!important;
}

.bg-grey {
  background-color: #525660!important;
}

.opacity-1 {
  opacity: 1!important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-small {
  font-size: 14px !important;
}

.align-items-center {
  align-items: center;
}

.mt-2 {
  margin-top: 20px;
}


/* =============================================
LIH News styles
================================================ */

#lih-news {
  position: relative;
  height: auto;
  overflow: hidden;
}

#lih-news h2 {
  font-size: 54px;
  font-family: 'brandingmedium';
  line-height: 52px; 
  margin-bottom: 30px;
}

#lih-news h2 strong {
  font-family: 'brandingbold';
  color: #f96d1f;
}




.bg-gradient-violet {
  background-image: url('../public/images/bg-gradient-violet.png');
  background-size: cover;
}

.bg-gradient-white {
  background: rgb(163,163,163);
  background: linear-gradient(0deg, rgba(163,163,163,1) 0%, rgba(247,247,247,1) 80%);
}

.bg-gradient-blue {
  background: rgb(17,20,50);
  background: linear-gradient(0deg, rgba(17,20,50) 0%, rgba(110,152,188,1) 80%);
}

.news--wrapper {
  display: grid;
  grid-template-columns: 6fr 6fr;
  height: 691px;
  max-width: 1200px;
  margin: 0 auto;

}

/* @media only screen and (max-width: 1200px) {
  .news--wrapper {
    padding: auto;
  }
} */

.news--container {
  /* padding: 0 2rem; */
}

.news--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* padding-top: 2rem; */
  padding: 20px;
  margin-bottom: 2rem;
  gap: 4rem;
  padding-top: 4rem;

}

.news--text img {
  margin: auto;
}

.news--cover {
  display: flex;
  align-items: normal;
}

.news--cover .desktop, .news--text .desktop {
  visibility: visible;
  display:inline-flex;
}
.news--cover .mobile, .news--text .mobile {
  visibility: hidden;
  display: none;
}

@media only screen and (max-width: 620px) {
.news--cover .desktop, .news--text .desktop {
  visibility: hidden;
  display: none;
}
.news--cover .mobile, .news--text .mobile {
  visibility: visible;
  display:inline-flex;
}
}

.news--btn { 
  background: #FFFFFF; 
  font-family: ABCModernBold;
  color: #000000;
  text-decoration: none;
  padding: 20px 40px;
  position: relative;
  transition: all .5s;
  max-width: 200px;
  margin: auto;
}

.news--btn:hover { 
  background: #FF7F00;
   color:#000000 !important;
}


@media only screen and (max-width: 920px) {

  .wrap-order--2 {
    order: 2;
  }

  .mobile-only {
    display: block!important;
  }

  .desktop-only {
    display: none!important;
  }

  .news--wrapper {
    flex-direction: column;
    gap: 0;
  }

  .news--wrapper {
    height: auto;
  }

}

@media only screen and (max-width: 768px) {

  .news--wrapper {
    flex-direction: column;
    gap: 0;
  }

  .news--text {
    padding: 2rem;
    gap: 2rem;
  }

}

@media only screen and (max-width: 620px) {

  .news--wrapper {
    grid-template-columns: 12fr;
    height: auto;
  }

  .news--wrapper {
    flex-direction: column;
    gap: 0;
  }

  .news--text {
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
  }

  .news--container img {
    max-width: 400px;
    padding-bottom: 20px;
    margin: 0 auto;
  }


}


.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-items {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.grid-container img {
  height: 140px;
  width: auto;
  margin-bottom: 10px;;
}

.featured-image-v2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
} 

.featured-image-v2.darken {
  filter: brightness(.8);
}


.portfolio-item.width-3x {
  width: 60%;
}

.mb-30 {
  margin-bottom: 30px;
}

#jobs-section {
  background-color: #f2f2f2;
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.job-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.job-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  flex: 1;             /* Allow the title to use remaining space */
  margin-right: 10px;  /* Optional space between title and button */
  margin-bottom: 0;
  text-align: left;
}

.learn-more {
  background-color: #f97316; /* Orange */
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap; /* Force the text to stay on one line */
  flex-shrink: 0;      /* Prevent the button from shrinking when space is tight */
  font-family: ABCModernBold;
}

.learn-more:hover {
  background-color: #ea580c; /* Darker orange */
  color: #fff !important;
}

#seeMoreJobs {
  color: #f97316;
  text-decoration: underline;
  width: auto;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

#seeMoreJobs:hover {
  opacity: 0.9;
}

@media screen and (max-width: 480px) {
  .job-title {
    font-size: 14px;
    
  }
}